import React from 'react';

function Footer(props) {
  return (
    <div className="bottom-page ">
      <div className="bottom-container" id="contact">
        <div className="row credits-bar mt-2 justify-content-center contact-row">
          <div className="">© Andrew Lo 2020</div>
        </div>
      </div>
    </div>
  );

}

export default Footer;
