import React from 'react';
class EmailMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.messageChange = this.messageChange.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  messageChange(events) {
    this.setState({ message: event.target.value });
  }

  handleSubmit(info) {
    this.setState({ loading: true });
    event.preventDefault();
    fetch('/api/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    })
      .then(() => {
        window.location.href = '/thankyou';
      })
      .catch(err => console.error(err));
    this.mailUser(info);
  }

  mailUser(info) {
    fetch('/api/emailcustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    })
      .catch(err => console.error(err));
  }

  goHome() {
    window.location.href = '/thankyou';
  }

  renderLoader() {
    if (this.state.loading) {
      return (<div className="m-auto loader"></div>);
    }
  }

  render() {
    return (
      <div className="container pr-2">
        <h1 className="contact-text text-center">Contact Me</h1>
        <form id="contact-form" className=" p-3" onSubmit={() => this.goHome()}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" id="name" onChange={this.handleNameChange} value={this.state.name}/>
          </div>
          <div className="m-auto loader-container">
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email Address</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" onChange={this.handleEmailChange} value={this.state.email}/>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea className="form-control" rows="5" id="message" onChange={this.messageChange} value={this.state.message}></textarea>
          </div>

          <button type="submit" className="d-flex send-button m-auto btn btn-primary" onClick={() => this.handleSubmit(this.state)}>
            Submit
          </button>
        </form>
        {this.renderLoader()}
      </div>
    );
  }
}

export default EmailMe;
