import React from 'react';

function ThankYou(props) {
  return (
    <div className="text-center container justify-content-center">
      <div className="thank-mesage pt-4">
      Thank you for your message!
      </div>
      <a
        href="https://andrewclo.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className=" img-fluid dice-logo"
          src="./images/Andrew-Lo-go.png"
        />
      </a>

    </div>
  );
}

export default ThankYou;
