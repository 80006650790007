import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import EmailMe from './contact';
import ThankYou from './thanks';
import Footer from './footer';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={EmailMe} />
          <Route path="/thankyou" component={ThankYou}/>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
